import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/custom.slider.css";
function CustomCarousel({ children }) {
	const settings = {
		dots: true,
		fade: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		pauseOnHover: true
	};

	return (
		<div className="slider-container">
			<Slider {...settings}>
				{children.map((item, index) => (
					<div key={index} className="carousel-slide">
						<img src={item.props.src} alt="" className="carousel-image" />
					</div>
				))}
			</Slider>
		</div>
	);
}

export default CustomCarousel;