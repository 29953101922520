import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./styles/article.css";

const RecommendationCard = (props) => {
    const { title, job_title, job_name, description, link } = props;
    return (
        <React.Fragment>
            <div className="">
                <div>{title}</div>
                <div className="homepage-recommendation-description">{job_title}</div>
                <div className="homepage-recommendation-description">{job_name}</div>
                <div
                    style={{ marginTop: '10px' }}
                    className="homepage-recommendation-description"
                >
                    {description.split('\n').map((text, index) => (
                        <React.Fragment key={index}>
                            {text}
                            <br />
                        </React.Fragment>
                    ))}
                </div>
                <div className="homepage-recommendation-link">
                    <Link to={link}>
                        Read Recommendation{" "}
                        <FontAwesomeIcon
                            style={{ fontSize: "10px" }}
                            icon={faChevronRight}
                        />
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
};

export default RecommendationCard;
