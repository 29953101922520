import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import "./styles/readArticle.css";
import { fetchData } from "../fetchData";
import SomethingWentWrong from "./error";
import LoadingUI from "../components/common/loading";

let ArticleStyle = styled.div``;


const ReadRecommendation = () => {
    let { slug } = useParams();

    const [recommendationData, setRecommendationData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRecommendationData = async () => {
            try {
                const recommendationData = await fetchData(`recommendations/${slug}`);
                setRecommendationData(recommendationData);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchRecommendationData();
    }, [slug]);

    if (isLoading) {
        return <LoadingUI active='recommendation' />;
    }

    if (error) {
        return <SomethingWentWrong />;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Recommendation from {recommendationData.recommendations.name} | Mehadi Hassan</title>
                <meta name="description" content={recommendationData.recommendations.recommendation} />
                <meta name="keywords" content={recommendationData.recommendations.recommendation.keywords} />
            </Helmet>

            <div className="page-content">
                <NavBar active="recommendation" />

                <div className="content-wrapper">
                    <div className="read-article-logo-container">
                        <div className="read-article-logo">
                            <Logo width={46} />
                        </div>
                    </div>

                    <div className="read-article-container">
                        <div className="read-article-wrapper">
                            <div className="title">
                                {recommendationData.recommendations.name}
                            </div>

                            <div className="subtitle">
                                {recommendationData.recommendations.job_title}, {recommendationData.recommendations.job_name}
                            </div>

                            <div style={{ marginTop: '10px' }} className="read-article-body">
                                <ArticleStyle>
                                    <div className="article-content">
                                        <div className="paragraph">
                                            {recommendationData.recommendations.recommendation.split('\n').map((text, index) => (
                                                <React.Fragment key={index}>
                                                    {text}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                            {recommendationData.recommendations.provided_on && (
                                                <p>
                                                    <i>This recommendation was provided on{' '}
                                                        <a href={recommendationData.recommendations.provided_on_url} target="_blank" rel="noopener noreferrer">
                                                            {recommendationData.recommendations.provided_on}
                                                        </a></i>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </ArticleStyle>
                            </div>
                        </div>
                    </div>
                    <div className="page-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReadRecommendation;
