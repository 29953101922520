import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import CustomCarousel from "../components/projects/customSlider";
import "./styles/readArticle.css";
import { API_URL, fetchData } from "../fetchData";
import SomethingWentWrong from "./error";
import LoadingUI from "../components/common/loading";

let ArticleStyle = styled.div``;


const ReadProject = () => {
    let { slug } = useParams();

    const [projectData, setProjectData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const projectData = await fetchData(`project/${slug}`);
                setProjectData(projectData);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjectData();
    }, [slug]);

    if (isLoading) {
        return <LoadingUI active='projects' />;
    }

    if (error || !projectData) {
        return <SomethingWentWrong />;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{projectData.project.name} | Mehadi Hassan</title>
                <meta name="description" content={projectData.project.long_description} />
                <meta name="keywords" content={projectData.project.keywords} />
            </Helmet>

            <div className="page-content">
                <NavBar active={'projects'} />

                <div className="content-wrapper">
                    <div className="read-article-logo-container">
                        <div className="read-article-logo">
                            <Logo width={46} />
                        </div>
                    </div>

                    <div className="read-article-container">
                        <div className="read-article-wrapper">
                            <div className="title">
                                {projectData.project.name}
                            </div>

                            <div className="subtitle">
                                Tech Used: {projectData.project.stack}
                            </div>

                            <div className="read-article-body">
                                <ArticleStyle>
                                    <div className="article-content">
                                        <CustomCarousel>
                                            {projectData.project_image.map((image, index) => {
                                                return <img key={index} src={`${API_URL}${image}`} alt={`Project ${index}`} />;
                                            })}
                                        </CustomCarousel>
                                        <div className="paragraph" style={{ marginTop: '40px' }} dangerouslySetInnerHTML={{ __html: projectData.project.long_description }}></div>
                                    </div>
                                </ArticleStyle>
                            </div>
                        </div>
                    </div>
                    <div className="page-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReadProject;
