import React from "react";
import ReactLoading from 'react-loading';
import NavBar from "./navBar";
import Logo from "./logo";


const LoadingUI = (props) => {
    const { active } = props;
    return (
        <div className="page-content">
            <NavBar active={active} />
            <div className="content-wrapper">
                <div className="projects-logo-container">
                    <div className="projects-logo">
                        <Logo width={46} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                    <ReactLoading type={'bars'} color={'#14b8a6'} height={667} width={375} />
                </div>
            </div>
        </div>
    )
}

export default LoadingUI;