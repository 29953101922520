import React from "react";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import Card from "../common/card";
import "./styles/works.css";
import { Link } from "react-router-dom";

const Research = ({ data }) => {
    // Function to chunk the data into pairs
    const chunkArray = (array, size) => {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    };

    // Chunk the data into pairs
    const worksChunks = chunkArray(data, 1);

    return (
        <div className="works">
            <Card
                icon={faBook}
                title="Research"
                body={
                    <div className="works-body">
                        {worksChunks.map((chunk, index) => (
                            <div className="work-row" key={index}>
                                {chunk.map((info, idx) => (
                                    <Link
                                        key={idx} // <-- Add key prop here
                                        to={info.url}
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="skills-award">
                                            <div className="work-info">
                                                <div className="work-title">{info.name}</div>
                                            </div>
                                            <div className="work-info">
                                                <div className="work-subtitle">{info.supervisors}</div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        ))}
                    </div>
                }
            />
        </div>
    );
};

export default Research;
