import React, { createContext, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Homepage from "./pages/homepage";
import About from "./pages/about";
import Projects from "./pages/projects";
import Contact from "./pages/contact";
import Notfound from "./pages/404";
import { TRACKING_ID } from "./data/tracking";
import "./app.css";
import ReadProject from "./pages/readProject";
import ReadRecommendation from "./pages/readRecommendation";
import Recommendation from "./pages/recommendation";
import { API_URL, getAuthorizationHeader } from "./fetchData";
export const MyContext = createContext();

function App() {
	const [cacheTime, setCacheTime] = useState(new Date().getTime());
	window.cacheTime = new Date().getTime();

	useEffect(() => {
		if (TRACKING_ID !== "") {
			ReactGA.initialize(TRACKING_ID);
		}
	}, []);

	useEffect(() => {
		const fetchCacheTime = async () => {
			const url = `${API_URL}/api/cache-time`;
			try {
				const response = await fetch(url, {
					method: "GET",
					headers: {
						Authorization: getAuthorizationHeader(),
					},
				});
				const data = await response.json();
				setCacheTime(data);
			} catch (error) {
				console.error("Error fetching cache time:", error);
			}
		};
		fetchCacheTime();
	}, []);

	useEffect(() => {
		if (
			cacheTime &&
			cacheTime.updated_at &&
			cacheTime.updated_at.updated_at
		) {
			window.cacheTime = cacheTime.updated_at.updated_at;
		}
	}, [cacheTime]);

	return (
		<MyContext.Provider value={{ cacheTime, setCacheTime }}>
			<div className="App">
				<Routes>
					<Route path="/" element={<Homepage />} />
					<Route path="/about" element={<About />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/project/:slug" element={<ReadProject />} />
					<Route
						path="/recommendations"
						element={<Recommendation />}
					/>
					<Route
						path="/recommendations/:slug"
						element={<ReadRecommendation />}
					/>
					<Route path="/contact" element={<Contact />} />
					<Route path="*" element={<Notfound />} />
				</Routes>
			</div>
		</MyContext.Provider>
	);
}

export default App;
