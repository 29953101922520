import React from "react";
import { faSchool } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";
import { Link } from "react-router-dom";

const Education = ({ data }) => {
    // Function to chunk the data into pairs
    const chunkArray = (array, size) => {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    };

    // Chunk the data into pairs
    const worksChunks = chunkArray(data, 2);

    return (
        <div className="works">
            <Card
                icon={faSchool}
                title="Education"
                body={
                    <div className="works-body">
                        {worksChunks.map((chunk, index) => (
                            <div className="work-row" key={index}>
                                {chunk.map((info, idx) => (
                                    <div className="work" key={idx}>
                                        <div className="work-info">
                                            <Link to={info.url} style={{ textDecoration: 'none', color: 'inherit' }} target="_blank" rel="noopener noreferrer"><div className="work-title">{info.location}</div></Link>
                                            <div className="work-location">{info.address}</div>
                                        </div>
                                        <div className="work-info">
                                            <div className="work-subtitle">{info.degree}</div>
                                            <div className="work-duration">{info.time}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                }
            />
        </div>
    );
};

export default Education;
