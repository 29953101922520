const SEO = [
	{
		page: "home",
		description:
			"I am a full stack developer developer with expertise in React and Python. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: ["Mehadi", "Mehadi H", "Mehadi Hassan", "mehadihn"],
	},

	{
		page: "about",
		description:
			"I am a full stack developer developer with expertise in React and Python. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: ["Mehadi", "Mehadi H", "Mehadi Hassan", "mehadihn"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Mehadi", "Mehadi H", "Mehadi Hassan", "mehadihn"],
	},

	{
		page: "projects",
		description:
			"Explore my innovative projects, many of which are open-source. Review the code, suggest improvements, and collaborate to foster learning and growth. Your feedback is always welcome.",
		keywords: ["Mehadi", "Mehadi H", "Mehadi Hassan", "mehadihn"],
	},

	{
		page: "recommendations",
		description:
			"Discover recommendations from talented individuals highlighting the impact and value I've brought to various projects. Read their experiences and share your feedback to help me grow and excel continually.",
		keywords: ["Mehadi", "Mehadi H", "Mehadi Hassan", "mehadihn"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project or have query, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Mehadi", "Mehadi H", "Mehadi Hassan", "mehadihn"],
	},
];

export default SEO;
