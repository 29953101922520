import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";


import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/recommendation.css";
import { fetchData } from "../fetchData";
import LoadingUI from "../components/common/loading";
import SomethingWentWrong from "./error";
import RecommendationCard from "../components/homepage/recommendationCard";

const Recommendation = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "recommendations");
	const [recommendationData, setRecommendationData] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchData("recommendations")
			.then((data) => {
				setRecommendationData(data.recommendations);
				setLoading(false);
			})
			.catch((error) => {
				setError(error);
				setLoading(false);
			});
	}, []); // Empty dependency array ensures this runs only once

	if (isLoading) {
		return <LoadingUI active='recommendation' />;
	}

	if (error) {
		return <SomethingWentWrong />;
	}

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Recommendations | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="recommendation" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
							Recommendations that Inspire Confidence
						</div>

						<div className="subtitle projects-subtitle">
							Throughout my career, I've had the privilege of working with numerous talented individuals who have shared their thoughts and experiences about our collaborations. These recommendations highlight the impact I've made and the value I've brought to various projects. I am grateful for their kind words and support, which continue to inspire me to strive for excellence. Feel free to read through these endorsements and gain insights into the positive experiences others have had working with me. Your feedback and recommendations are always welcome, as they help me grow and improve continually.
						</div>

						{recommendationData && (
							<div className="homepage-after-title">
								<div className="homepage-recommendations">
									{recommendationData.map((reco, index) => (
										<div className="homepage-recommendation" key={index + 1}>
											<RecommendationCard
												title={reco.name}
												job_name={reco.job_name}
												job_title={reco.job_title}
												description={`${reco.recommendation.substring(0, 500)}...`}
												link={`/recommendations/${reco.slug}`}
											/>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Recommendation;
