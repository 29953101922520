const INFO = {
	main: {
		title: "Mehadi Hassan",
		name: "Mehadi Hassan",
		email: "hi@mehadihassan.com",
		logo: "../Mehadi_Logo.jpg",
	},

	socials: {
		twitter: "https://twitter.com/mehadihn",
		github: "https://github.com/mehadihn",
		linkedin: "https://linkedin.com/in/mehadihassan",
		stackoverflow: "https://stackoverflow.com/users/14487282/mehadi",
	},

	homepage: {
		title: "Crafting innovative software solutions with seamless functionality.",
		description:
			"I have a passion for creating software and web applications and have previous experience in full stack development and software engineering. Over the years, I have engaged in numerous projects and take pride in the progress I've achieved. Many of these projects are open-source, allowing others to explore, contribute, and provide suggestions for improvements. I believe in the power of collaboration for learning and growth, and I am always open to new ideas and feedback.",
	},

	about: {
		title: "I am Mehadi Hassan, currently residing in St. John's, NL, with roots in Bangladesh.",
		description:
			"I have a passion for creating software and web applications and have previous experience in full stack development and software engineering. Over the years, I have engaged in numerous projects and take pride in the progress I've achieved. Many of these projects are open-source, allowing others to explore, contribute, and provide suggestions for improvements. I believe in the power of collaboration for learning and growth, and I am always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},
};

export default INFO;
